import UpdateEmitterI, { Scope, DetectableElementI } from './UpdateEmitter.interface';
import Lres from '@/dto/security/Lres.interface';

class InternalEmitter {
  public readonly registrationId: string;
  public readonly fn: Function;
  public readonly detectableElement: DetectableElementI;
  constructor(registrationId: string, fn: Function, detectableElement: DetectableElementI) {
    this.registrationId = registrationId;
    this.fn = fn;
    this.detectableElement = detectableElement;
  }
}

export default class UpdateEmitter implements UpdateEmitterI {

  private static logToConsole = false;
  private detectableElement?: DetectableElementI;
  private readonly parent: String;
  protected readonly emitters = new Array<InternalEmitter>();

  constructor(parent: String) {
    this.parent = '' + parent;
  }

  private log(emitter: InternalEmitter, action: string): void {
    if (!UpdateEmitter.logToConsole) return;
    const date = new Date().getTime();
    const msg = date + ' -> UpdateEmitter(' + this.parent + '): callback [' + emitter.registrationId + '] ' + action;
    console.debug(msg);
  }

  private emitIfDocumentHasDetectableElement(emitter: InternalEmitter, scope?: Scope, data?: any) {
    const found = document.getElementById('' + emitter.detectableElement.elementId);
    if (!found) return;
    const fn = emitter.fn;
    const sc = scope === undefined ? Scope.GENERIC : scope;
    fn(sc, data);
    this.log(emitter, 'triggered');
  }

  registerUpdateCallback(_registrationId: string, fn: Function, detectableElement: DetectableElementI): void {
    const registrationId = _registrationId && _registrationId.length ? '' + _registrationId : 'undefined';
    if (typeof fn !== 'function') {
      const date = new Date().getTime();
      const msg = date + ' -> UpdateEmitter(' + this.parent + '): callback [' + registrationId + '] ' + 'typeof !== function, is: ' + typeof fn;
      console.error(msg);
    }
    //
    const emitter = new InternalEmitter(registrationId, fn, detectableElement);
    this.emitters.push(emitter);
    this.emitIfDocumentHasDetectableElement(emitter, Scope.REGISTER_UPDATE_CALLBACK, undefined);
  }

  setLogToConsole(bool: Boolean): void {
    UpdateEmitter.logToConsole = bool === true;
  }

  emit(scope?: Scope, data?: any): void {
    for (let idx = 0; idx < this.emitters.length; idx++) {
      const emitter = this.emitters[idx];
      this.emitIfDocumentHasDetectableElement(emitter, scope, data);
    }
  }

}
