import EmploymentI from './Employment.interface';
import Organization, { OrganizationSample } from './Organization.class';

export default class Employment implements EmploymentI {

  public jobTitle?: String = '';
  public seniority?: String = '';
  public department?: String = '';

  public organization?: Organization;

  constructor(employment?: EmploymentI) {
    this.jobTitle = employment ? employment.jobTitle || '' : '';
    this.seniority = employment ? employment.seniority || '' : '';
    this.department = employment ? employment.department || '' : '';
    this.organization = employment
      ? new Organization(employment.organization)
      : new Organization();
  }

}

export class EmploymentSample extends Employment {
  constructor() {
    super();
    this.jobTitle = 'sample jobTitle';
    this.department = 'sample department';
    this.seniority = 'sample seniority';
    this.organization = new OrganizationSample();
  }
}
