import ValueOfI     from './ValueOf.interface';
import Condition    from './Condition.interface';
import { Operator } from './ValueOf.interface';

import {
  NumberCondition,
  NumberStringCondition,
  StringCondition,
  ArrayCondition,
} from './Condition.class';

export default class ValueOf implements ValueOfI {

  private value: any;

  constructor(value: any) {
    this.value = value;
  }

  isBoolean(): Boolean {
    return typeof this.value === 'boolean';
  }

  isNumber(): Boolean {
    return typeof this.value === 'number';
  }

  isNumberAnd(operator: Operator, value?: any): NumberCondition {
    return new NumberCondition(this.isNumber(), this.value, operator, value);
  }

  isNumberOrString(): Boolean {
    const nLeft = Number(this.value);
    if (isNaN(nLeft)) return false;
    return typeof nLeft === 'number';
  }

  isNumberOrStringAnd(operator: Operator, value?: any): NumberStringCondition {
    return new NumberStringCondition(this.isNumberOrString(), this.value, operator, value);
  }

  isString(): Boolean {
    return typeof this.value === 'string';
  }

  isStringAnd(operator: Operator, value?: any): StringCondition {
    return new StringCondition(this.isString(), this.value, operator, value);
  }

  isArray(): Boolean {
    return Array.isArray(this.value);
  }

  isArrayAnd(operator: Operator, value?: any): ArrayCondition {
    return new ArrayCondition(this.isArray(), this.value, operator, value);
  }

  isUuid(): Boolean {
    if (!this.isString()) return false;
    const s = '' + this.value;
    const length = s.length;
    const numberOfHyphens = s.split('-').length - 1;
    return length === 36 && numberOfHyphens === 4;
  }

}
