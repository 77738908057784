import HasNameId                              from '@/dto/_id/HasNameId.class';
import OrganizationI, { ThinOrganizationI }   from './Organization.interface';
import PostalAddress, { PostalAddressSample } from './PostalAddress.class';

export class ThinOrganization extends HasNameId {

  constructor(organization?: ThinOrganizationI) {
    super(
      organization ? organization.id : undefined,
      organization ? organization.name || '' : ''
    );
  }

}

export default class Organization extends ThinOrganization implements OrganizationI {

  public numberOfEmployees?: Number = 1;
  public businessField?: String = '';
  public revenue?: Number = 0;
  public approved?: boolean = false;

  public phoneNumber?: String = '';
  public companyUrl?: String = '';
  public linkedInProfile?: String = '';
  public postalAddress?: PostalAddress;
  
  constructor(organization?: Organization) {
    super(organization);
    this.numberOfEmployees = organization ? organization.numberOfEmployees || 1 : 1;
    this.businessField = organization ? organization.businessField || '' : '';
    this.revenue = organization ? organization.revenue || 0 : 0;
    this.approved = organization ? organization.approved : false;
    this.phoneNumber = organization ? organization.phoneNumber || '' : '';
    this.companyUrl = organization ? organization.companyUrl || '' : '';
    this.linkedInProfile = organization ? organization.linkedInProfile || '' : '';
    this.postalAddress = organization
      ? new PostalAddress(organization.postalAddress)
      : new PostalAddress();
  }

}

export class OrganizationSample extends Organization {
  constructor() {
    super({ id: -1 });
    this.name = 'sample name';
    this.numberOfEmployees = 123;
    this.businessField = 'sample businessField';
    this.revenue = 456789;
    this.phoneNumber = 'sample phoneNumber';
    this.companyUrl = 'sample companyUrl';
    this.linkedInProfile = 'sample linkedInProfile';
    this.postalAddress = new PostalAddressSample();
  }
}
