import Vue from 'vue';
import
  StorageServiceI,
  {
    StoreKey,
    ServerStorePropsI
  } from './StorageService.interface';
import {
  PreferencesI,
  ColorScheme,
  NavBarPosition,
  // NavBarAppearance,
  NavBarShape,
  NavBarType,
  Language,
  ViewHeight,
} from '@/components/preferences/Preferences.interface';
import { Preferences }    from '@/components/preferences/Preferences.class';
import UpdateEmitter      from '@/var/UpdateEmitter.class';
import * as CryptoTS      from 'crypto-ts';
import { Scope, DetectableElementI }       from '@/var/UpdateEmitter.interface';
import { getMySettings, updateMySettings } from '@/http/security/users';

export default class StorageService implements StorageServiceI {

  private static updateEmitter = new UpdateEmitter('StorageService (static)');
  registerUpdateCallback(_registrationId: string, fn: Function, detectableElement: DetectableElementI): void {
    StorageService.updateEmitter.registerUpdateCallback(_registrationId, fn, detectableElement);
  }

  hasKey(key: StoreKey): Boolean {
    switch (key) {
      case StoreKey.USER:
      case StoreKey.LRES:
      case StoreKey.USER_PHOTO_URL:
      case StoreKey.CLIENT_LOGO_URL:  return localHasKey(key); break;
      case StoreKey.PREFS:            return serverHasKey(key); break;
      case StoreKey.SECURITY:         return encryptedHasKey(key); break;
      default: break;
    }
    return false;
  }

  get<T>(key: StoreKey): T | undefined {
    switch (key) {
      case StoreKey.USER:
      case StoreKey.LRES:
      case StoreKey.USER_PHOTO_URL:
      case StoreKey.CLIENT_LOGO_URL:  return localGet<T>(key); break;
      case StoreKey.PREFS:            return serverGet<T>(key); break;
      case StoreKey.SECURITY:         return encryptedGet<T>(key); break;
      default: break;
    }
    return undefined;
  }

  store(key: StoreKey, value: string): void {
    switch (key) {
      case StoreKey.USER:
      case StoreKey.LRES:
      case StoreKey.USER_PHOTO_URL:
      case StoreKey.CLIENT_LOGO_URL:  localStore(key, value); break;
      case StoreKey.PREFS:            serverStore(key, value); break;
      case StoreKey.SECURITY:         encryptedStore(key, value); break;
      default: break;
    }
    StorageService.updateEmitter.emit(Scope.UPDATE, '' + key);
    return;
  }

  remove(key: StoreKey): void {
    switch (key) {
      case StoreKey.USER:
      case StoreKey.LRES:
      case StoreKey.USER_PHOTO_URL:
      case StoreKey.CLIENT_LOGO_URL:  localRemove(key); break;
      case StoreKey.PREFS:            serverRemove(key); break;
      case StoreKey.SECURITY:         encryptedRemove(key); break;
      default: break;
    }
    StorageService.updateEmitter.emit(Scope.UPDATE, '' + key);
    return;
  }

}

/** @private */
function getDocumentCookie(key: StoreKey): string | undefined {
  const cookieValue =
    document
      .cookie
      .split("; ")
      .find((row) => row.startsWith(key + '='))
      ?.split("=")[1];
  return cookieValue;
}

/** @private */
function parse(s: string): any {
  if (s.length > 0 && s[0] === '{') {
    return JSON.parse(s);
  }
  return s;
}

// LOCAL

/** @private */
function localHasKey(key: StoreKey): Boolean {
  const item = localStorage.getItem(key);
  return typeof item === 'string';
}

/** @private */
function localGet<T>(key: StoreKey): T | undefined {
  if (!localHasKey(key)) return undefined;
  const item = '' + localStorage.getItem(key);
  const parsed = parse(item);
  return (parsed as T);
}

/** @private */
function localStore(key: StoreKey, value: string): void {
  localStorage.setItem(key, value);
}

/** @private */
function localRemove(key: StoreKey): void {
  localStorage.removeItem(key);
}
// SERVER

/** @private */
function serverHasKey(key: StoreKey): Boolean {
  // key is always cached as a cookie
  return cookieHasKey(key);
}

/** @private */
function serverGet<T>(key: StoreKey): T | undefined {
  // if present locally: do no fetch from server
  let preferences: Preferences;
  const value = localGet<T>(key);
  if (value !== undefined) {
    preferences = new Preferences(value as any);
    const prepareType = preferences as unknown;
    return prepareType as T;
  }
  preferences = new Preferences();
  localStore(key, JSON.stringify(preferences.getProperties()));
  getMySettings()
    .then((response: any) => { preferences.update(response); localStore(key, JSON.stringify(preferences.getProperties())); })
    .catch((error: any) => { console.error('unable to retrieve user settings'); /* will fall back on local storage */ } )
    .finally(() => { /* todo */ });
  const prepareType = preferences as unknown;
  return prepareType as T; // updated async
}

/** @private */
function serverStore(key: StoreKey, value: string): void {
  const parsedValue = JSON.parse(value);
  const stored = localGet<Preferences>(key);
  const preferences = new Preferences(parsedValue);
  if (stored !== undefined && new Preferences(stored).equals(preferences)) {
    return; // value has not changed
  }
  const serverStoredProps = preferences.getProperties();
  localStore(key, JSON.stringify(serverStoredProps));
  updateMySettings(serverStoredProps)
    .then((response: any) => { /* silent feature */ })
    .catch((error: any) => { console.error('unable to store user settings'); /* will fall back on local storage */ } )
    .finally(() => { /* todo */ });
  return;
}

/** @private */
function serverRemove(key: StoreKey): void {
  updateMySettings()
    .then((response: any) => { /* silent feature */ })
    .catch((error: any) => { console.error('unable to remove user settings'); /* will fall back on local storage */ } )
    .finally(() => { /* todo */ });
  return localRemove(key);
}

// COOKIE

/** @private */
function cookieHasKey(key: StoreKey): Boolean {
  return typeof getDocumentCookie(key) === 'string';
}

/** @private */
function cookieGet<T>(key: StoreKey): T | undefined {
  if (!cookieHasKey(key)) return undefined;
  const item = '' + getDocumentCookie(key);
  const parsed = parse(item);
  return (parsed as T);
}

/** @private */
function cookieStore(key: StoreKey, value: string): void {
  const maxAge = 'max-age=' + 60*60*24*365*99; // 99 years
  const security = 'SameSite=Lax; Secure;';
  document.cookie = key + '=' + value + ';' + maxAge + ';' + security;
}

/** @private */
function cookieRemove(key: StoreKey): void {
  document.cookie = key + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

// ENCRYPED

class Encryption {
  static KEY = 'StorageService';
  static getHexKey(secundaryKey: string): string {
    return secundaryKey + '$' + Encryption.KEY;
  }
  static getIV(secundaryKey: string): string {
    const anyVue: any = Vue;
    return Encryption.KEY + '$' + secundaryKey;
  }
}

/** @private */
function string32length(s: string) {
  let ano = '';
  for (let idx = 0; idx < 32; idx++) {
    const c = idx < s.length ? s[idx]: '0';
    ano += c;
  }
  return ano;
}

/** @private */
function getEncryptionKey(): any {
  const user = '' + localGet<String>(StoreKey.USER);
  const key = Encryption.getHexKey('' + user);
  return CryptoTS.enc.Hex.parse(string32length(key));
}

/** @private */
function getEncryptionOptions(): any {
  const user = '' + localGet<String>(StoreKey.USER);
  const iv = Encryption.getIV('' + user);
  const options = {
    iv: CryptoTS.enc.Hex.parse(string32length(iv)),
    mode: CryptoTS.mode.CBC,
    padding: CryptoTS.pad.PKCS7,
  }
  return options;
}

/** @private */
function encryptedHasKey(key: StoreKey): Boolean {
  // is stored locally after encrypting
  return localHasKey(key);
}

/** @private */
function encryptedGet<T>(key: StoreKey): T | undefined {
  if (!encryptedHasKey(key)) return undefined;
  const encryption = getEncryptionKey();
  const options = getEncryptionOptions();
  const encrypted = '' + localGet<String>(key);
  const decrypted = CryptoTS.AES.decrypt(encrypted, encryption, options).toString(CryptoTS.enc.Utf8);
  const parsed = parse(decrypted);
  return (parsed as T);
}

/** @private */
function encryptedStore(key: StoreKey, value: string): void {
  const encryption = getEncryptionKey();
  const options = getEncryptionOptions();
  const encryptedValue = CryptoTS.AES.encrypt(value, encryption, options).toString();
  localStore(key, encryptedValue);
}

/** @private */
function encryptedRemove(key: StoreKey): void {
  // is stored locally after encrypting
  return localRemove(key);
}

export class ServerStoreProps extends UpdateEmitter implements ServerStorePropsI {

  public colorScheme: ColorScheme;
  public navBarShape: NavBarShape;
  public navBarPosition: NavBarPosition;
  public navBarType: NavBarType;
  // public horNavBarAppearance: NavBarAppearance;
  // public verNavBarAppearance: NavBarAppearance;
  public language: Language;
  public stepsViewHeight: ViewHeight;

  constructor(o?: ServerStorePropsI) {
    super('ServerStoreProps');
    this.colorScheme = o === undefined ? ColorScheme.LIGHT : o.colorScheme;
    this.navBarShape = o === undefined ? NavBarShape.NORMAL : o.navBarShape;
    this.navBarPosition = o === undefined ? NavBarPosition.LEFT : o.navBarPosition;
    this.navBarType = o === undefined ? NavBarType.VERTICAL : o.navBarType;
    // this.horNavBarAppearance = o === undefined ? NavBarAppearance.NORMAL : o.horNavBarAppearance;
    // this.verNavBarAppearance = o === undefined ? NavBarAppearance.NORMAL : o.verNavBarAppearance;
    this.language = o === undefined ? Language.EN : o.language;
    this.stepsViewHeight = o === undefined ? ViewHeight.SLIM : o.stepsViewHeight;
  }

  getProperties(): any {
    const props: any = {};
    props.colorScheme = this.colorScheme;
    props.navBarShape = this.navBarShape;
    props.navBarPosition = this.navBarPosition;
    props.navBarType = this.navBarType;
    props.language = this.language;
    props.stepsViewHeight = this.stepsViewHeight;
    return props;
  }

  equals(preferences: ServerStorePropsI): Boolean {
    const thisStringified = JSON.stringify(this.getProperties());
    const preferencesStringified = JSON.stringify(preferences.getProperties());
    return thisStringified === preferencesStringified;
  }

  update(o: ServerStorePropsI): void {

    let emitChanges = false;

    emitChanges = emitChanges || o.colorScheme !== this.colorScheme;
    switch (o.colorScheme) {
      case ColorScheme.DARK: this.colorScheme = o.colorScheme; break;
      case ColorScheme.LIGHT:
      default: this.colorScheme = ColorScheme.LIGHT; break;
    }

    emitChanges = emitChanges || o.navBarShape !== this.navBarShape;
    switch (o.navBarShape) {
      case NavBarShape.SLIM: this.navBarShape = o.navBarShape; break;
      case NavBarShape.NORMAL:
      default: this.navBarShape = NavBarShape.NORMAL; break;
    }

    emitChanges = emitChanges || o.navBarPosition !== this.navBarPosition;
    switch (o.navBarPosition) {
      case NavBarPosition.RIGHT: this.navBarPosition = o.navBarPosition; break;
      case NavBarPosition.LEFT:
      default: this.navBarPosition = NavBarPosition.LEFT; break;
    }

    emitChanges = emitChanges || o.navBarType !== this.navBarType;
    switch (o.navBarType) {
      case NavBarType.HORIZONTAL:
      case NavBarType.BOTH: this.navBarType = o.navBarType; break;
      case NavBarType.VERTICAL:
      default: this.navBarType = NavBarType.VERTICAL; break;
    }

    // this.horNavBarAppearance = o.horNavBarAppearance;
    // this.verNavBarAppearance = o.verNavBarAppearance;

    emitChanges = emitChanges || o.language !== this.language;
    switch (o.language) {
      case Language.EN:
      default: this.language = Language.EN; break;
    }

    // does not trigger general UI update
    switch (o.stepsViewHeight) {
      case ViewHeight.MEDIUM:
      case ViewHeight.FULL: this.stepsViewHeight = o.stepsViewHeight; break;
      case ViewHeight.SLIM:
      default: this.stepsViewHeight = ViewHeight.SLIM; break;
    }

    if (emitChanges) this.emit();

  }

}
