export class UISettings {

  clientAccent?: String;

  constructor(settings?: UISettings) {
    const s: any = settings ? settings : {};
    this.clientAccent = s.clientAccent;
  }
}

export class DefaultUISettings {
  readonly clientAccent = '#12d7f2';
}
