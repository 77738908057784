import Vue                      from 'vue';
import RouteParamsI             from './RouteParams.interface';
import RouteParams              from './RouteParams.class';
import router                   from '@/router';
import { RouteName }            from '@/router/Route.interface';
import { RouteAccessBuilderI }  from './RouteVerifyer.interface';
import { MessageType }          from '@/services/msg/MessageService.interface';

export default class RouteAccessBuilder implements RouteAccessBuilderI {

  private readonly router = router;
  private readonly msgService = Vue.prototype.$global.msg;
  private readonly routeName: RouteName;
  private readonly principalHasAccess: Boolean;
  private params: any;

  constructor(routeName: RouteName, principalHasAccess: Boolean) {
    this.routeName = routeName;
    this.principalHasAccess = principalHasAccess === true;
  }

  withRouteParams(params: RouteParamsI): RouteAccessBuilderI {
    this.params = params.toAny();
    return this;
  }
  withParams(params: any): RouteAccessBuilderI {
    this.params = params;
    return this;
  }

  go(): void {
    const currentRoute = this.router.currentRoute || {};
    if (currentRoute.name === '' + this.routeName) {
      const currentRouteParams = JSON.stringify(new RouteParams(currentRoute.params));
      const newRouteParams = JSON.stringify(new RouteParams(this.params));
      if (currentRouteParams === newRouteParams) {
        // no changes: do nothing
        return;
      }
      this.router.replace({ name: this.routeName, params: this.params });
      return;
    }
    if (this.principalHasAccess) {
      this.router.push({ name: this.routeName, params: this.params });
    } else {
      this.msgService.addMessage(MessageType.WARNING, 'Unsufficient access rights');
    }

  }

}
