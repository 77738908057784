import HasId          from '@/dto/_id/HasId.class';
import PostalAddressI from './PostalAddress.interface';

export default class PostalAddress extends HasId implements PostalAddressI {

  municipalityName?: String = '';
  postalCode?: String = '';
  region?: String = '';
  countryName?: String = '';
  streetName?: String = '';
  streetNumber?: String = '';

  constructor(address?: PostalAddressI) {
    super(address ? address.id : undefined);

    this.municipalityName = address ? address.municipalityName || '' : '';
    this.postalCode = address ? address.postalCode || '' : '';
    this.region = address ? address.region || '' : '';
    this.countryName = address ? address.countryName || '' : '';
    this.streetName = address ? address.streetName || '' : '';
    this.streetNumber = address ? address.streetNumber || '' : '';

  }

}

export class PostalAddressSample extends PostalAddress {
  constructor() {
    super({ id: -1 });
    this.municipalityName = 'sample municipalityName';
    this.postalCode = 'sample postalCode';
    this.region = 'sample region';
    this.countryName = 'sample countryName';
    this.streetName = 'sample streetName';
    this.streetNumber = 'sample streetNumber';
  }
}
