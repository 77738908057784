export enum MessageType {
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  FATAL = 'FATAL',
}

export enum DeleteType {
  SLOW = 'SLOW',
  FAST = 'FAST',
}

import UpdateEmitter  from '@/var/UpdateEmitter.interface';
import Message        from './Message.interface';
import HttpMessage    from '@/http/error/HttpMessage.interface';

export default interface MessageService extends UpdateEmitter {

  addMessage(type: MessageType, msg: String, httpMessage?: HttpMessage): void;
  getMessages(deleteType: DeleteType, messageType?: MessageType): Array<Message>;
  lastMessage(deleteType: DeleteType, messageType?: MessageType): Message | undefined;

}
