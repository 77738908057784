<template>
  <div id="odin" class="odin-color-scheme">
    <router-view />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import '@/assets/css/scheme-light.scss';
import '@/assets/css/scheme-dark.scss';
import '@/assets/css/scheme-both.scss';

// User preferences
import { Preferences } from '@/components/preferences/Preferences.class';
Vue.prototype.$userPrefs = new Preferences();

export default {};
</script>

<style lang="scss">

:root {
  //Default
  --client-accent-color: var(--scheme-primary);
  --default-client-accent-color: var(--scheme-primary);
  --missing-scheme-color: rgb(0, 255, 0);
  
  //Success
  --scheme-success: rgb(59, 142, 131);
  --scheme-success-highlight: rgb(52, 124, 115);

  //Warning
  --scheme-warning: rgb(234, 154, 39);
  --scheme-warning-highlight: rgb(205, 135, 34);

  //Danger
  --scheme-danger: rgb(157, 56, 79);
  --scheme-danger-highlight: rgb(137, 49, 69);

  //Info
  --scheme-info: rgb(180, 180, 180);
  --scheme-info-highlight: rgb(140, 140, 140);

  //Primary
  --scheme-primary: rgb(18, 215, 242);
  --scheme-primary-highlight: rgb(14, 155, 173);

  //Themes
  --scheme-main-bg: rgb(40, 97, 129);
  --scheme-main-text: rgb(46, 112, 148);

  //Light
  --scheme-main-menu-light: rgb(147, 175, 181);

  --scheme-bg-light: white;
  --scheme-menu-bg-light: rgb(253, 253, 233);
  --scheme-menu-border-light: rgb(233, 233, 213);
  --scheme-text-light: rgb(26, 69, 93);
  --scheme-header-highlight-light: rgb(40, 97, 129);
  --scheme-disabled-light: var(--scheme-info);

  //Dark
  --scheme-main-menu-dark: rgb(48, 76, 91);
  
  --scheme-bg-dark: rgb(55, 55, 55);
  --scheme-menu-bg-dark: rgb(85, 85, 85);
  --scheme-menu-border-dark: rgb(125, 125, 125);
  --scheme-text-dark: rgb(250, 250, 250);
  --scheme-disabled-dark: rgb(101, 113, 121); 
}

#odin {

  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #606266;
  font-size: 14px;
  line-height: 20px;

  .clearfix {
    overflow: auto;
  }
  .clearfix::after {
    content: "";
    clear: both;
    display: table;
  }

}

</style>
