<template>
	<div id="unsubscribe" class="unsubscribe-page">
		<div class="unsubscribe-page__content">
			<div class="unsubscribe-page__content__header">
				<h1>Thank You!</h1>
			</div>
			<div class="unsubscribe-page__content__body">
				<p class="unsubscribe-email"> {{ emailAddress }} </p> 
				<p class="unsubscribe-email-detail">Has been unsubscribed from our outreach list.</p>
				<div v-if="context != '' && clientLogoUrl != ''" :class="'unsubscribe-environment-wrapper ' + environment.code">
					<Environment
						:environment="environment"
						:client-logo-url="clientLogoUrl"
						height="200px"
						width="200px"
					/>
				</div>
				<font-awesome-icon v-else icon="check-circle" size="10x" />
			</div>
		</div>
	</div>
</template>
	
<script lang="ts">
import EnvironmentClass 		from '@/dto/security/Environment.class';
import Environment 					from '@/components/environment/Environment.vue';
import Vue 									from 'vue';
import { testUrl } 					from '@/http/login/login';
import StorageService 			from '@/services/storage/StorageService.class';
import { StoreKey } 				from '@/services/storage/StorageService.interface';

	
export default Vue.extend({
	components: {
		Environment,
	},

	data() {
		return {
			environment: new EnvironmentClass(),
			storage: new StorageService(),
			emailAddress: '',
			context: '',
			clientLogoUrl: '',
		};
	},

	created() {
		this.init();
	},

	methods: {
		init() {
			this.emailAddress = this.$route.query.email.toString() ?? '';
			this.context = this.$route.query.context.toString() ?? '';
			if (this.context != '') {
				this.testUrlOfClientLogo();
			}
		},

		testUrlOfClientLogo() {
      testUrl('logos/' + this.context)
        .then((response: any) => {})
        .catch((statusAndUrl: any) => {
          this.setClientLogoUrl(statusAndUrl);
        });
    },
    setClientLogoUrl(response: any) {
      if (!response || response.status !== '200') {
        this.clientLogoUrl = '';
        return;
      }
      this.clientLogoUrl = '' + response.url;
      this.storage.store(StoreKey.CLIENT_LOGO_URL, this.clientLogoUrl.toString());
    },
	}
});

</script>
	
<style lang="scss">
	.unsubscribe-page {
		background-color: var(--scheme-main-bg);
		width: 100vw;
  	height: 100vh;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;

		.unsubscribe-page__content {
			display: flex;
			flex-basis: 50%;
			width: 50%;
			height: 50%;
			flex-direction: column;
			justify-content: center;
			box-shadow: 1px 1px 1px 1px var(--scheme-main-menu-dark);
			background-color: transparentize($color: white, $amount: 0.90);
			color: var(--scheme-menu-border-light);
		}
	}

	.unsubscribe-email {
		font-size: large;
		font-weight: bold;
		margin-bottom: 0px;
	}

	.unsubscribe-email-detail {
		margin-top: 0px;
		margin-bottom: 36px;
	}

	div.unsubscribe-environment-wrapper {
    width: 200px;
    margin: 0 auto 0 auto;
    text-align: center;
		display: inline-flex;
  }
</style>
	