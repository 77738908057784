import HasNameId                            from '@/dto/_id/HasNameId.class';
import ClientI, { ThinClientI }             from './Client.interface';
import Organization, { OrganizationSample } from './Organization.class';
import Person, { PersonSample }             from './Person.class';
import { UISettings, DefaultUISettings }     from '@/components/environment/UISettings.class';

export class ThinClient extends HasNameId {

  public readonly uuid?: String;

  constructor(client?: ThinClientI) {
    super(
      client ? client.id : undefined,
      client ? client.name || '' : ''
    );
    this.uuid = client ? '' + client.uuid : 'no-client-uuid';
  }

}

export default class Client extends ThinClient implements ClientI {

  public code?: String;
  public organization?: Organization;
  public responsible?: Person;
  public settings?: UISettings;

  constructor(client?: Client) {
    super(client);
    this.code = client ? client.code || '' : '';
    this.organization = client
      ? new Organization(client.organization)
      : new Organization();
    this.responsible = client
      ? new Person(client.responsible)
      : new Person();
    this.settings = client && client.settings !== undefined
      ? client.settings
      : new UISettings();
  }

}

export class ClientSample extends Client {
  constructor() {
    super({ id: -1 });
    this.name = 'sample name';
    this.code = 'sample code';
    this.organization = new OrganizationSample();
    this.responsible = new PersonSample();
    this.settings = new DefaultUISettings();
  }
}
