import LresI from './Lres.interface';
import { Feature, Action } from './ObjectType.interface';
import { ThinPerson } from '@/dto/party/Person.class';
import { ThinPersonI } from '@/dto/party/Person.interface';
import { ThinClient } from '@/dto/party/Client.class';
import { ThinClientI } from '@/dto/party/Client.interface';

class InvalidClient implements ThinClientI {
  public readonly id?: String = 'invalidClientId';
  public readonly uuid?: String = 'invalidClientUuid';
  public readonly name?: String = 'invalidClient';
}

class InvalidPerson implements ThinPersonI {
  public readonly id?: String = 'invalidPersonId';
  public readonly firstName?: String = 'invalidPerson';
  public readonly lastName?: String = 'invalidPerson';
  public readonly email?: String = 'email@person.invalid';
  getFullName(): String {
    return this.firstName + ' ' + this.lastName;
  }
  getFirstNameOrDefault(): String {
    return '' + this.firstName;
  }
}

export class ValidLres implements LresI {

  private readonly invalid: Boolean;
  private readonly client: ThinClientI;
  private readonly person: ThinPersonI;
  private readonly allowedActions: Array<String>;
  private readonly isPo: Boolean;
  private readonly isAdmin: Boolean;
  private readonly isSu: Boolean;

  constructor(o: any) {
    try {

      const client = o === undefined ? {} : o.client || {};
      this.client = new ThinClient(client);
      if (this.client.uuid === undefined) throw new Error('Security problem detected');

      const person = o === undefined ? {} : o.person || {};
      this.person = new ThinPerson(person);
      if (this.person.id === undefined) throw new Error('Security problem detected');

      const allowedActions = o === undefined ? [] : o.allowedActions || [];
      if (!Array.isArray(allowedActions) || allowedActions.length === 0) throw new Error('Security problem detected');
      this.allowedActions = [];
      for (let idx = 0; idx < allowedActions.length; idx++) {
        const item = '' + allowedActions[idx];
        this.allowedActions.push(item);
      }
      this.isPo = o ? o.isPO === true : false;
      this.isAdmin = o ? o.isAdministrator === true : false;
      this.isSu = o ? o.isSuperUser === true : false;
      this.invalid = false;

    } catch(ex) {
      this.invalid = true;
      this.client = new InvalidClient();
      this.person = new InvalidPerson();
      this.allowedActions = [];
      this.isPo = false;
      this.isAdmin = false;
      this.isSu = false;
    }
  }

  isInvalid(): Boolean {
    return this.invalid;
  }

  getClient(): ThinClientI {
    if (this.invalid) return new InvalidClient();
    return this.client;
  }
  getPerson(): ThinPersonI {
    if (this.invalid) return new InvalidPerson();
    return this.person;
  }
  getAllowedActions(): Array<String> {
    if (this.invalid) return [];
    return this.allowedActions;
  }
  isPlatformOwner(): Boolean {
    if (this.invalid) return false;
    return this.isPo;
  }
  isAdministrator(): Boolean {
    if (this.invalid) return false;
    return this.isAdmin;
  }
  isSuperUser(): Boolean {
    if (this.invalid) return false;
    return this.isSu;
  }

  canList(feature: Feature): Boolean {
    return this.allowedActionsContains(feature, Action.LISTVIEW);
  }
  canRead(feature: Feature): Boolean {
    return this.allowedActionsContains(feature, Action.READ);
  }
  canCreate(feature: Feature): Boolean {
    return this.allowedActionsContains(feature, Action.CREATE);
  }
  canUpdate(feature: Feature): Boolean {
    return this.allowedActionsContains(feature, Action.UPDATE);
  }
  canDelete(feature: Feature): Boolean {
    return this.allowedActionsContains(feature, Action.DELETE);
  }
  canAdmin(feature: Feature): Boolean {
    return this.allowedActionsContains(feature, Action.ADMIN);
  }

  private allowedActionsContains(feature: Feature, action: Action): Boolean {
    const allowedAction = feature + '-' + action;
    return this.allowedActions.includes(allowedAction);
  }

}

export class InvalidLres implements LresI {

  isInvalid(): Boolean {
    return true;
  }

  getClient(): ThinClientI {
    return new InvalidClient();
  }
  getPerson(): ThinPersonI {
    return new InvalidPerson();
  }
  getAllowedActions(): Array<String> {
    return [];
  }
  isPlatformOwner(): Boolean {
    return false;
  }
  isAdministrator(): Boolean {
    return false;
  }
  isSuperUser(): Boolean {
    return false;
  }

  canList(feature: Feature): Boolean {
    return false;
  }
  canRead(feature: Feature): Boolean {
    return false;
  }
  canCreate(feature: Feature): Boolean {
    return false;
  }
  canUpdate(feature: Feature): Boolean {
    return false;
  }
  canDelete(feature: Feature): Boolean {
    return false;
  }
  canAdmin(feature: Feature): Boolean {
    return false;
  }

}
