export enum ColorScheme {
  LIGHT = 'LIGHT',
  DARK = 'DARK',
}

export enum NavBarPosition {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

// export enum NavBarAppearance {
//   NORMAL = 'NORMAL',
//   DARKER = 'DARKER',
//   LIGHTER = 'LIGHTER',
// }

export enum NavBarShape {
  NORMAL = 'NORMAL',
  SLIM = 'SLIM',
}

export enum NavBarType {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
  BOTH = 'BOTH',
}

export enum Language {
  EN = "EN",
}

export enum ViewHeight {
  SLIM = 'SLIM',
  MEDIUM = 'MEDIUM',
  FULL = 'FULL',
}

import { ServerStorePropsI }  from '@/services/storage/StorageService.interface';

export interface PreferencesI extends ServerStorePropsI {

  // horNavBarAppearance: NavBarAppearance;
  // verNavBarAppearance: NavBarAppearance;

  uiClass: String;

  update(o: PreferencesI): void;

}
