<template>
  <div id="logon" :class="'odin-color-scheme ' + $userPrefs.uiClass">

    <el-row class="odin-login-wrapper" type="flex" justify="center" align="middle">
      <el-col :span="24" class="odin-login">
        <el-form v-model="model" label-position="top" class="customCssFormLabels" size="medium">

          <div :class="'environment-wrapper ' + environment.code">
            <Environment
              :environment="environment"
              :client-logo-url="clientLogoUrl"
              width="200px"
              height="100px"
            />
          </div>
          <h1 class="text-light text-h1" style="text-align: center">{{title}}</h1>
          <h2 class="text-light text-h2" style="text-align: center">{{subtitle}}</h2>

          <el-form-item class="text-light text-p" prop="model.u" label="Email">
            <el-input
              v-model="model.u"
              :readonly="!email"
              placeholder="your.email@company.com"
              clearable
              @change="updateEnvironment"
              @blur="updateEnvironment"
            >
              <template #append>
                <font-awesome-icon icon="user" class="input-icon" />
              </template>
            </el-input>
          </el-form-item>

          <el-form-item :error="error" label="Password" prop="model.p" class="text-light text-p" >
            <el-input
              v-model="model.p"
              :disabled="!model.isValidEmail()"
              :type="show ? 'input' : 'password'"
              :class="'input-password '+ inputPasswordClass"
              clearable
              @keyup.native.enter="$emit('click')"
              @input="error = ''"
            >
              <template #append>
                <font-awesome-icon
                    :icon="show ? 'eye-slash' : 'eye'"
                    class="password-icon"
                    @click="show = !show"
                    @mouseenter="inputPasswordClass = 'hover'"
                    @mouseleave="inputPasswordClass = ''"
                  />
              </template>
            </el-input>
          </el-form-item>

          <el-form-item
            v-if="model.confirm"
            :error="model.passwordsMatch() ? '' : 'Password does not match !'"
            label="Confirm Password" prop="model.compare" class="text-light text-p" >
            <el-input
              v-model="model.compare"
              :disabled="!model.isValidEmail()"
              :type="show ? 'input' : 'password'"
              :class="'input-password '+ inputPasswordClass"
              clearable
              @input="error = ''"
            >
              <template #append>
                <font-awesome-icon
                    :icon="show ? 'eye-slash' : 'eye'"
                    class="password-icon"
                    @click="show = !show"
                    @mouseenter="inputPasswordClass = 'hover'"
                    @mouseleave="inputPasswordClass = ''"
                  />
              </template>
            </el-input>
          </el-form-item>

          <el-form-item >
            <el-link
              v-if="forgot"
              type="primary"
              class="forgot-password"
              @click="$emit('forgot')"
            >
              Forgot Password?
            </el-link>
          </el-form-item>

          <el-form-item>
            <el-button
              :disabled="!model.isValidEmail || !model.passwordsMatch()"
              type="primary"
              class="sign-in-button"
              size="medium"
              style="width: 100%"
              @click="$emit('click')"
            >
              {{ '' + button }}
            </el-button>
          </el-form-item>

          <el-form-item v-if="true">
            <el-link
              type="primary"
              class="go-back"
              @click="gotoLogon"
            >
              <font-awesome-icon icon="arrow-left" />
              <span class="text-light"> Go Back</span>
            </el-link>
          </el-form-item>

          <!-- disabled: functional cookies always active
          <el-form-item prop="model.useStorage" label="" class="cookies">
            <el-switch
              style="display: block"
              v-model="model.useStorage"
              active-color="#67C23A"
              inactive-color="#C0C4CC"
              active-text="Allow cookies"
              inactive-text="Cookies disabled">
            </el-switch>
          </el-form-item>
          -->

        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script lang="ts">

import Environment          from '@/components/environment/Environment.vue';
import EnvironmentClass     from '@/dto/security/Environment.class';
import Password             from './Password.class';
import StorageService       from '@/services/storage/StorageService.class';
import {
    getEnvironment,
    testUrl,
  }                         from '@/http/login/login';
import { StoreKey }         from '@/services/storage/StorageService.interface';
import { RouteName }        from '@/router/Route.interface';

import Vue from 'vue';
export default Vue.extend({
  name: 'InputPassword',
  components: {
    Environment,
  },

  props: {
    model: {
      type: Password,
      required: true,
      default() { return new Password(); }
    },
    title: {
      type: String,
      required: true,
      default() { return 'Welcome'; }
    },
    subtitle: {
      type: String,
      required: true,
      default() { return 'Log into your account'; }
    },
    email: {
      type: Boolean,
      required: true,
      default() { return true; }
    },
    forgot: {
      type: Boolean,
      required: true,
      default() { return true; }
    },
    button: {
      type: String,
      required: true,
      default() { return 'Sign In'; }
    },
    error: {
      type: String,
      required: true,
      default() { return ''; }
    },
  },

  data() {
    return {
      environment: new EnvironmentClass(),
      storage: new StorageService(),
      clientLogoUrl: '',
      show: false,
      inputPasswordClass: '',
    };
  },

  mounted() {
    this.removeStoredClientLogo();
    const myLogin = this.storage.get<string>(StoreKey.USER);
    if (myLogin !== undefined) {
      this.updateEnvironment();
    }
  },

  methods: {

    removeStoredClientLogo() {
      this.storage.remove(StoreKey.CLIENT_LOGO_URL);
      this.storage.remove(StoreKey.USER_PHOTO_URL);
      this.clientLogoUrl = '';
    },

    updateEnvironment() {
      if (!this.model.isValidEmail()) return;
      this.removeStoredClientLogo();
      getEnvironment(this.model.u)
        .then((response: any) => { this.setEnvironment(response); })
        .catch((error: any) => {
          this.setEnvironment(undefined);
        })
        .finally(() => {
          // this.option.isLoading = false;
        });
    },

    setEnvironment(response: any) {
      this.environment = new EnvironmentClass(response);
      this.storage.store(StoreKey.USER, this.model.u);
      this.testUrlOfClientLogo();
    },

    testUrlOfClientLogo() {
      testUrl('logos/' + this.environment.uuid)
        .then((response: any) => { /* do nothing */ })
        .catch((statusAndUrl: any) => {
          this.setClientLogoUrl(statusAndUrl);
        });
    },
    setClientLogoUrl(response: any) {
      if (!response || response.status !== '200') {
        this.clientLogoUrl = '';
        return;
      }
      this.clientLogoUrl = '' + response.url;
      this.storage.store(StoreKey.CLIENT_LOGO_URL, this.clientLogoUrl);
    },

    gotoLogon(): void {
      this.$router.push({ name: RouteName.Logon });
    }
    //
  },
});

</script>

<style lang="scss">

#logon {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  width: 100vw;
  height: 100vh;
  font-size: 14px;
  line-height: 130%;
  overflow: auto;

  div.odin-login-wrapper {
    width: 400px;
    min-width: 360px;
    margin: 0 auto;
    overflow: auto;
    padding-top: 96px;
    padding-bottom: 32px;
  }

  label.el-form-item__label {
    padding: 0;
  }

  a.forgot-password {
    float: right;
    height: 40px;
  }
  a.go-back {
    float: left;
  }

  .text-light {
    color: var(--scheme-menu-bg-light);
  }

  .text-h1 {
    font-size: 32px;
    font-weight: bolder;
    font-family: Arial, Helvetica, sans-serif;
  }

  .text-h2 {
    font-size: 18px;
    font-weight: bolder;
    font-family: Arial, Helvetica, sans-serif;
  }

  .text-p {
    font-size: 16px;
    font-weight: normal;
    font-family: Arial, Helvetica, sans-serif;
  }

  .sign-in-button {
    background-color: var(--scheme-menu-bg-light);
    border-color: var(--scheme-menu-bg-light);

    span {
      color: var(--scheme-menu-bg-dark);
      font-size: 16px;
      font-weight: bold;
      font-family: Arial, Helvetica, sans-serif;
    }

    &:hover {
      background-color: var(--client-accent-color);
      border-color: var(--client-accent-color);
      span {
        color: var(--scheme-menu-bg-light);
      }
    }
  }

  label.el-form-item__label {
    color: var(--scheme-menu-bg-light);
  }
  a.create-account {
    display: block;
    width: fit-content;
    margin: 0 auto;
  }

  div.input-password.hover div.el-input-group__append {
    color: white;
    background-color: #C0C4CC;
  }

  div.environment-wrapper {
    width: 200px;
    margin: 0 auto 24px auto;
    text-align: center;
  }

}
</style>
