export enum Action {
  LISTVIEW = 'LISTVIEW',
  READ = 'READ',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  ADMIN = 'ADMIN',
}

export enum Feature {
  REPLY_TEMPLATE = 'REPLY_TEMPLATE',
  IMPORT_SET = 'IMPORT_SET',
  CATEGORIZATION = 'CATEGORIZATION',
  PERSON = 'PERSON',
  CLIENT = 'CLIENT',
  CAMPAIGN_REPORT = 'CAMPAIGN_REPORT',
  CAMPAIGN = 'CAMPAIGN',
  CAMPAIGN_STEP = 'CAMPAIGN_STEP',
  UNSUBSCRIBE = 'UNSUBSCRIBE',
  ALLOWED_ACTION = 'ALLOWED_ACTION',
  DOMAIN = 'DOMAIN',
  ROLE = 'ROLE',
  GRANTED_ROLE = 'GRANTED_ROLE',
  ORGANIZATION = 'ORGANIZATION',
  USER = 'USER',
  INSIGHT = 'INSIGHT',
  BLACKLIST = 'BLACKLIST',
}

export default interface ObjectType  {

  readonly id: String;
  readonly name: String;
  readonly description: String;

}
