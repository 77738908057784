import Vue from 'vue';
import Odin from './Odin.vue';
import router from './router';

/* Vue 2 and Vue 3 use the same icon importing syntax */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(fas, far, fab);
Vue.component('font-awesome-icon', FontAwesomeIcon);

// element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import elementLocale from 'element-ui/lib/locale/lang/en';
Vue.use(ElementUI, { locale: elementLocale });

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(Odin),
}).$mount('#odin');