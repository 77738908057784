import RouteParams    from './RouteParams.interface';
import UpdateEmitter  from '@/var/UpdateEmitter.interface';
import Lres           from '@/dto/security/Lres.interface';
import { Privileges } from '@/dto/security/Privileges';
import { RouteName }  from '@/router/Route.interface';

export default interface RouteVerifyer extends UpdateEmitter {

  getStatus(): VerificationStatus;
  getUrlLastPart(vueRoute: any): String;
  getUrlSecondLastPart(vueRoute: any): String;
  start(): void;
  setValid(): void;
  setInvalid(error: any): void;
  setInvalidPath(): void;
  setUnauthorized(): void;

  requiresPrivileges(lres: Lres, routeName: RouteName | String): Boolean;
  getRequiredPrivileges(lres: Lres, routeName: RouteName | String): Array<Privileges>;
  principalHasRoutePrivileges(lres: Lres, routeName: RouteName | String): Boolean;
  accessRoute(lres: Lres, routeName: RouteName): RouteAccessBuilderI;

}

export enum VerificationStatus {
  VERIFYING = 'VERIFYING',
  VALID = 'VALID',
  INVALID_PATH = 'INVALID_PATH',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export interface RouteAccessBuilderI {
  withRouteParams(params: RouteParams): RouteAccessBuilderI; // allow method chaining
  withParams(params: any): RouteAccessBuilderI; // allow method chaining
  go(): void;
}
