import { PreferencesI }       from './Preferences.interface';
import { ServerStorePropsI }  from '@/services/storage/StorageService.interface';
import { ServerStoreProps }   from '@/services/storage/StorageService.class';

export class Preferences extends ServerStoreProps implements PreferencesI {

  public uiClass: String = '';

  constructor(o?: PreferencesI) {
    super(o);
    this.uiClass = this.getUIClass();
  }

  update(o: PreferencesI): void {

    super.update(o);
    this.uiClass = this.getUIClass();

  }

  private getUIClass(): String {
    let c = '';
    c += 'scheme-' + this.colorScheme + ' ';
    c += 'position-' + this.navBarPosition + ' ';
    return c;
  }

}
