import Vue from 'vue';
import LresContainerI from './LresContainer.interface';
import Lres from '@/dto/security/Lres.interface';
import StorageService from '@/services/storage/StorageService.class';
import { InvalidLres } from '@/dto/security/Lres.class';
import { Initializer } from '@/dto/security/Initializer.class';
import { StoreKey } from '@/services/storage/StorageService.interface';

export default class LresContainer implements LresContainerI {

  private lres?: Lres = undefined;

  clear(): void {
    this.lres = undefined;
  }

  get(): Lres {
    const storage = new StorageService();
    if (storage && typeof storage.hasKey === 'function' && typeof storage.get === 'function') {
      const lres = storage.get(StoreKey.LRES);
      if (lres !== undefined) {
        // value found
        if (this.lres === undefined || this.lres.isInvalid()) {
          this.lres = new Initializer().getLresInstance(lres);
        }
      } else {
        // value not found
        this.lres = new InvalidLres();
        console.warn('authentication not set or expired');
      }
    } else {
      // no storage
      this.lres = new InvalidLres();
      console.warn('funtional storage are required during authentication');
    }
    return this.lres;
  }

}
