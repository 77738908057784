import EnvironmentI from './Environment.interface';
import { valueOf }  from '@/utils/checks/value';
import { Operator } from '@/utils/checks/value/ValueOf.interface';
import { UISettings, DefaultUISettings } from '@/components/environment/UISettings.class';

export default class Environment implements EnvironmentI {

  public readonly id: Number;
  public readonly uuid: String;
  public readonly name: String;
  public readonly code: String;
  public readonly settings: UISettings;

  constructor(o?: EnvironmentI) {

    const obj: any = o === undefined ? {} : o;

    this.id = valueOf(obj.id).isNumberAnd(Operator.GREATER_THAN, 0).check() ? (obj.id as number) : 1;
    this.uuid = '' + obj.uuid;
    this.name = valueOf(obj.name).isStringAnd(Operator.LENGTH_GREATER_THAN, 1).check() ? '' + obj.name : 'Outbridge';
    this.code = valueOf(obj.code).isStringAnd(Operator.LENGTH_GREATER_THAN, 1).check() ? '' + obj.code : 'OUTBR';
    this.settings = obj.settings ? new UISettings(obj.settings) : new DefaultUISettings();

  }

}
