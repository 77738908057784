import request  from '@/http/request';
import User     from '@/dto/security/User.interface';
import { ServerStorePropsI }  from '@/services/storage/StorageService.interface';

export function getUser(userId: Number | String): any {
  return request({
    url: '/users/' + userId ,
    method: 'get',
    subTitlePropName: 'email',
  });
};

export function replacePassword(oldPassword: String, newPassword: String): any {
  return request({
    // url: '/users/',
    url: '/users/me/password',
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    spinnerVisible: true,
    progressVisible: false,
    data: { oldPassword: oldPassword, password: newPassword },
    onSuccessMessage: 'Password changed',
  });
};

export function getMySettings(): any {
  return request({
    url: '/users/me/settings',
    method: 'get',
    spinnerVisible: false,
  });
};

export function updateMySettings(settings?: any): any {
  const clean: any = settings === undefined ? {} : settings;
  return request({
    url: '/users/me/settings',
    method: 'put',
    data: clean,
    spinnerVisible: false,
  });
};

export function deletePhoto(): any {
  return request({
    url: '/users/me/photo',
    method: 'delete',
  });
};
