import ConditionI   from './Condition.interface';
import { Operator } from './ValueOf.interface';

class Condition {

  protected parentResult: Boolean;
  protected left?: any;
  protected operator?: Operator;
  protected right?: any;

  constructor (parentResult: Boolean, left?: any, operator?: Operator, right?: any) {
    this.parentResult = parentResult;
    this.left = left;
    this.operator = operator;
    this.right = right;
  }

}

export class NumberCondition extends Condition implements ConditionI {

  constructor (parentResult: Boolean, left?: any, operator?: Operator, right?: any) {
    super(parentResult, left, operator, right);
  }

  and(operator: Operator, right: any): NumberCondition {
    return new NumberCondition(this.check(), this.left, operator, right);
  }
  check(): Boolean {
    if (this.parentResult === false) return false;
    var type = typeof this.left;
    if (type !== 'number') return false;
    type = typeof this.right;
    if (type !== 'number') return false;
    if (this.operator === undefined) return false;

    switch (this.operator) {
      case Operator.EQUAL: return this.left === this.right; break;
      case Operator.NOT_EQUAL: return this.left !== this.right; break;
      case Operator.GREATER_THAN: return this.left > this.right; break;
      case Operator.GREATER_THAN_OR_EQUAL: return this.left >= this.right; break;
      case Operator.LESS_THAN: return this.left < this.right; break;
      case Operator.LESS_THAN_OR_EQUAL: return this.left <= this.right; break;
      default: break;
    }
    return false;
  }

}

export class NumberStringCondition extends Condition implements ConditionI {

  constructor (parentResult: Boolean, left?: any, operator?: Operator, right?: any) {
    super(parentResult, '' + left, operator, right);
  }

  and(operator: Operator, right: any): NumberStringCondition {
    return new NumberStringCondition(this.check(), this.left, operator, right);
  }
  check(): Boolean {
    if (this.parentResult === false) return false;
    const nLeft = Number(this.left);
    if (isNaN(nLeft)) return false;
    const type = typeof this.right;
    if (type !== 'number') return false;
    if (this.operator === undefined) return false;

    switch (this.operator) {
      case Operator.EQUAL: return nLeft === this.right; break;
      case Operator.NOT_EQUAL: return nLeft !== this.right; break;
      case Operator.GREATER_THAN: return nLeft > this.right; break;
      case Operator.GREATER_THAN_OR_EQUAL: return nLeft >= this.right; break;
      case Operator.LESS_THAN: return nLeft < this.right; break;
      case Operator.LESS_THAN_OR_EQUAL: return nLeft <= this.right; break;
      default: break;
    }
    return false;
  }

}

export class StringCondition extends Condition implements ConditionI {

  constructor (parentResult: Boolean, left?: any, operator?: Operator, right?: any) {
    super(parentResult, left, operator, right);
  }

  and(operator: Operator, right: any): StringCondition {
    return new StringCondition(this.check(), this.left, operator, right);
  }
  check(): Boolean {
    if (this.parentResult === false) return false;
    if (this.operator === undefined) return false;
    var type = typeof this.left;
    if (type !== 'string') return false;
    // only check "left" value
    switch (this.operator) {
      case Operator.EMPTY: return this.left.length === 0; break;
      case Operator.NOT_EMPTY: return this.left.length > 0; break;
      default: break;
    }

    type = typeof this.right;
    if (type === 'string') {
      switch (this.operator) {
        case Operator.EQUAL: return this.left === this.right; break;
        case Operator.EQUAL_IGNORE_CASE: return this.left.equalsIgnoreCase(this.right); break;
        case Operator.NOT_EQUAL: return this.left !== this.right; break;
        case Operator.LENGTH_GREATER_THAN: return this.left.length > this.right.length; break;
        case Operator.LENGTH_GREATER_THAN_OR_EQUAL: return this.left.length >= this.right.length; break;
        case Operator.LENGTH_LESS_THAN: return this.left.length < this.right.length; break;
        case Operator.LENGTH_LESS_THAN_OR_EQUAL: return this.left.length <= this.right.length; break;
        default: break;
      }
    }

    if (type === 'number') {
      switch (this.operator) {
        case Operator.LENGTH_GREATER_THAN: return this.left.length > this.right; break;
        case Operator.LENGTH_GREATER_THAN_OR_EQUAL: return this.left.length >= this.right; break;
        case Operator.LENGTH_LESS_THAN: return this.left.length < this.right; break;
        case Operator.LENGTH_LESS_THAN_OR_EQUAL: return this.left.length <= this.right; break;
        default: break;
      }
    }

    return false;
  }

}

export class ArrayCondition extends Condition implements ConditionI {

  constructor (parentResult: Boolean, left?: any, operator?: Operator, right?: any) {
    super(parentResult, left, operator, right);
  }

  and(operator: Operator, right: any): ArrayCondition {
    return new ArrayCondition(this.check(), this.left, operator, right);
  }
  check(): Boolean {
    if (this.parentResult === false) return false;
    if (this.operator === undefined) return false;
    if (!Array.isArray(this.left)) return false;
    // only check "left" value
    switch (this.operator) {
      case Operator.EMPTY: return this.left.length === 0; break;
      case Operator.NOT_EMPTY: return this.left.length > 0; break;
      default: break;
    }

    if (!Array.isArray(this.right)) return false;

    switch (this.operator) {
      case Operator.LENGTH_GREATER_THAN: return this.left.length > this.right.length; break;
      case Operator.LENGTH_GREATER_THAN_OR_EQUAL: return this.left.length >= this.right.length; break;
      case Operator.LENGTH_LESS_THAN: return this.left.length < this.right.length; break;
      case Operator.LENGTH_LESS_THAN_OR_EQUAL: return this.left.length <= this.right.length; break;
      default: break;
    }

    // comparison based on JSON string
    const jsonLeft = JSON.stringify(this.left);
    const jsonRight = JSON.stringify(this.right);
    switch (this.operator) {
      case Operator.EQUAL: return jsonLeft === jsonRight; break;
      case Operator.NOT_EQUAL: return jsonLeft !== jsonRight; break;
      default: break;
    }

    return false;
  }

}
