import HasId                            from '@/dto/_id/HasId.class';
import Employment, { EmploymentSample } from './Employment.class';
import Campaign from '@/dto/campaign/Campaign.interface';
import
  PersonI,
  {
    ThinPersonI,
    ThinPersonProps,
    RecipientProps,
    RecipientI,
    PersonProps,
  } from './Person.interface';

export class ThinPerson extends HasId implements ThinPersonI {

  public firstName?: String = '';
  public lastName?: String = '';
  public email?: String = '';

  constructor(o?: ThinPersonProps) {
    super(o ? o.id : undefined);
    this.firstName = o ? o.firstName || '' : '';
    this.lastName = o ? o.lastName || '' : '';
    this.email = o ? o.email || '' : '';
  }

  getFullName(): String {
    let fn = '';
    if (this.firstName !== undefined && this.firstName.length > 0) fn = this.firstName + ' ';
    return fn + this.lastName;
  }
  getFirstNameOrDefault(): String {
    if (this.firstName !== undefined && this.firstName.length > 0) return '' + this.firstName;
    return '' + this.lastName;
  }

}

export class Recipient extends ThinPerson implements RecipientI {

  public readonly emailAddressType?: String = 'CATCHALL';
  public readonly expired?: boolean = true;

  constructor(o?: RecipientProps) {
    super(o);
    this.emailAddressType = o
      ? o.emailAddressType || 'CATCHALL'
      : 'CATCHALL';
    this.expired = o ? !!o.expired : true;
  }

  toString(): String {
    return '<' + this.email + '> ' + this.getFullName();
  }
  toHtml(): String {
    return '&lt;' + this.email + '&gt;&nbsp;<strong>' + this.getFullName() + '</strong>';
  }

}

export class CampaignRecipient extends Recipient {

  public readonly emailAddressType?: String = 'REGULAR';
  public readonly expired?: boolean = false;

  constructor(o?: Campaign) {
    super(undefined);
    this.firstName = '';
    this.lastName = o ? o.senderFullName || 'anonymous' : 'anonymous';
    const domain: any  = o && o.domain ? o.domain : 'no.domain';
    this.email = o ? o.senderEmailName || 'no.sender' : 'no.sender';
    this.email += '@' + domain;
  }

}

export default class Person extends Recipient implements PersonI {

  public title?: String = undefined;
  public phoneNumber?: String = '';
  public birthDay?: String = '';

  public mostRecentEmployment?: Employment;

  constructor(o?: PersonProps) {
    super(o);
    this.title = o ? o.title : undefined;
    this.phoneNumber = o ? o.phoneNumber || '' : '';
    this.birthDay = o ? o.birthDay || undefined : undefined;
    this.mostRecentEmployment = o
      ? new Employment(o.mostRecentEmployment)
      : new Employment();
  }

}

export class PersonSample extends Person {
  constructor() {
    super({ id: -1 });
    this.firstName = 'sample firstName';
    this.lastName = 'sample lastName';
    this.email = 'sample.email@domain.com';
    this.title = 'MR';
    this.phoneNumber = 'sample phoneNumber';
    this.mostRecentEmployment = new EmploymentSample();
  }

}
