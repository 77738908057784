import HasNameIdI from './HasNameId.interface';
import HasId      from './HasId.class';

export default class HasNameId extends HasId implements HasNameIdI {

  public name?: String;

  constructor(id?: Number | String, name?: String) {
    super(id);
    this.name = name === undefined ? '' : '' + name;
  }

}
